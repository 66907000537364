import "../App.css";
import { ThemeProvider } from "styled-components";
import Signup from "./Signup";
import Login from "./Login";
import EmailLogin from "./EmailLogin";
import TwoStepLogin from "./TwoStepLogin";
import ForgotPassword from "./ForgotPassword";
import UpdateProfile from "./UpdateProfile";
import PasswordReset from "./PasswordReset";
import Dashboard from "./Dashboard";
import PrivateRoute from "./PrivateRoute";
import { Container } from "react-bootstrap";
import { AuthProvider } from "../contexts/AuthContext";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { theme } from "./styles/themeConfig";
import AlreadyLoggedInPage from "./AlreadyLoggedInPage";
import '../assets/css/elevate.css'
import PhoneNumberLogin from "./PhoneNumberLogin";
import { version } from "../../package.json";

function App() {
  document.body.style.backgroundColor = "#EAEDED";
  return (
    <ThemeProvider theme={theme}>
      <Container
        className="d-flex alight-items-center justify-content-center"
        style={{ minHeight: "100vh" }}
      >
        <div className="w-100" style={{ maxWidth: "100%" }}>
          <Router>
            <AuthProvider>
              <Switch>
                <PrivateRoute exact path="/" component={Dashboard} />
                <PrivateRoute
                  path="/update-profile"
                  component={UpdateProfile}
                />
                <Route path="/signup" component={Signup} />
                <Route path="/login" component={Login} />
                <Route
                  path="/phone-number-login"
                  component={PhoneNumberLogin}
                />
                <Route path="/email-login" component={EmailLogin} />
                <Route path="/forgot-password" component={ForgotPassword} />
                <Route path="/reset-password" component={PasswordReset} />
                <Route path="/two-step-login" component={TwoStepLogin} />
                <Route
                  path="/already-logged-in"
                  component={AlreadyLoggedInPage}
                />
              </Switch>
            </AuthProvider>
          </Router>
        </div>
      </Container>
      <div style={{ position: "fixed", bottom: 5, right: 20 }}>
        <p style={{ color: "grey", fontSize: "0.9rem" }}>v{version}</p>
      </div>
    </ThemeProvider>
  );
}

export default App;
