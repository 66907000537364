import { Base64 } from 'js-base64';
import { auth } from '../firebase';
import { parseJSON, sanitizeValue } from './utils';


export const redirectCurrentUser = async(loginRes) => {
  let tokenId = await auth.currentUser.getIdToken()
  const newReturnBody = {
    userName: loginRes.displayName,
    refreshToken: loginRes.refreshToken,
    token: loginRes._lat,
    tokenId: tokenId,
    email: loginRes.email,
    phoneNumber: loginRes.phoneNumber,
    signIn: true,
    signUp: false,
    actionType: null,
  }


  let loginResults = JSON.stringify(newReturnBody);
  const encodedData = Base64.encode(loginResults);
  localStorage.encodedDataVariance = JSON.stringify(encodedData)
  let redirectTo = `/already-logged-in`
  const urlParam = sanitizeValue(localStorage.urlParam)
  if(urlParam){
    redirectTo = `/already-logged-in?entry=${urlParam}`
  }

  // REDIRECT TO A SINGLE PAGE
  window.location.href = redirectTo
};

export const redirectUser = (loginRes, method, history) => {  
  if (loginRes?.user) {
    const isSignIn = loginRes.operationType === "signIn";
    const newReturnBody = {
      userName: loginRes.user.displayName,
      refreshToken: loginRes.user.za,
      email: loginRes.user.email,
      phoneNumber: loginRes.user.phoneNumber,
      signIn: isSignIn,
      signUp: !isSignIn,
      actionType: method,
    };

    let loginResults = JSON.stringify(newReturnBody);
    const encodedData = Base64.encode(loginResults);
    let storage = parseJSON(localStorage.siteSettings);

    let redirectUrl = sanitizeValue(storage?.redirectTo)
    if(redirectUrl){
      redirectUrl = `${redirectUrl}${encodedData}`;
    }else{
      redirectUrl = '/'
    }

    window.location.href = redirectUrl;
  } else {
    history.push("/");
  }
};